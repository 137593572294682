<template>
  <nav
    class="navbar navbar-expand-md navbar-dark fixed-top bg-dark"
    style="border-radius: unset; padding-bottom: unset; margin-bottom: unset"
  >
    <router-link :to="{ name: 'Public-Home' }" class="navbar-brand">
      <img class="" style="width: 182px" src="/assets/logo/l2.svg" alt />
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul
        class="navbar-nav mx-auto w-100"
        style="
          margin-right: 21px !important;
          height: 21px;
          justify-content: center;
        "
      >
        <li class="nav-item">
          <a class="nav-link mx-1" href="#">رمز ارز</a>
        </li>

        <li class="nav-item">
          <a class="nav-link mx-1" href="#"> مرور بازار</a>
        </li>
        <li class="nav-item">
          <a class="nav-link mx-1" href="#">طلا</a>
        </li>

        <li class="nav-item">
          <a class="nav-link mx-1" href="#">تبادلات مالی</a>
        </li>
        <li class="nav-item">
          <a class="nav-link mx-1" href="#">قبوض</a>
        </li>
        <li class="nav-item">
          <a class="nav-link mx-1" href="#">درگاه واسط</a>
        </li>
        <li class="nav-item">
          <a class="nav-link mx-1" href="#">وبلاگ</a>
        </li>

        <li class="nav-item">
          <a class="nav-link mx-1" href="#">درباره‌ما</a>
        </li>
        <li class="nav-item">
          <router-link class="nav-link mx-1" :to="{ name: 'Public-ContactUs' }"
            >تماس با ما
          </router-link>
        </li>
        <li class="nav-item">
          <a
            class="nav-link mx-1"
            href="#"
            style="
              background: linear-gradient(
                180deg,
                rgba(232, 114, 43, 0.25),
                rgba(255, 151, 88, 0.25)
              );
              border: 1px solid rgba(232, 114, 43, 0.5);
              border-radius: 2000px;
              color: #f1813c !important;
              display: inline-flex;
              font-size: 14px;
              font-weight: 500;
              line-height: 14px;
              margin-inline-start: 8px;
              padding-inline-end: 12px;
              padding-bottom: 4px;
              padding-inline-start: 12px;
              padding-top: 4px;
              white-space: pre-wrap;
            "
            >تازه وارد هستید؟
          </a>
        </li>
      </ul>
      <i
        class="bi bi-download mx-2"
        style="color: #fff !important; font-size: 21px"
      ></i>
      <router-link
        :to="{ name: 'PanelUser-Dashboard' }"
        class="btn btn-primary mx-1"
        style="
          border: unset;
          background-image: linear-gradient(
            to right top,
            #07976f,
            #07936c,
            #068e69,
            #068a66,
            #068663,
            #068663,
            #068663,
            #068663,
            #068a66,
            #068e69,
            #07936c,
            #07976f
          );
          height: 35px;
          font-size: 12px;
          border-radius: 7px;
        "
      >
        &nbsp;&nbsp;&nbsp; ورود / ثبت‌نام &nbsp;&nbsp;&nbsp;&nbsp;
      </router-link>
    </div>
  </nav>
</template>

<script>
import PrTextLogo from "@/components/custom/PrTextLogo.vue";

export default {
  components: {
    PrTextLogo,
  },
  data() {
    return {
      isBoxOpen: false,
      userAgentDetails: {
        screenType: null,
      },
      urls: [
        { text: "صفحه اصلی", name: "PublicHome", urlName: "", icon: "" },
        { text: "وبلاگ", name: "PublicBlogs", urlName: "", icon: "" },
        { text: "قوانین", name: "PublicTerms", urlName: "", icon: "" },

        { text: "درباره‌ما", name: "PublicAboutUs", urlName: "", icon: "" },
        { text: "تماس با ما", name: "PublicContactUs", urlName: "", icon: "" },
        { text: "همکاری", name: "", urlName: "", icon: "" },
        { text: "سوالات متداول", name: "PublicFaq", urlName: "", icon: "" },
        { text: "فروشگاه", name: "PublicStore", urlName: "", icon: "" },
      ],
    };
  },

  methods: {
    goLoginPage() {
      this.$router.push({ name: "PanelUser-Dashboard" });
    },
    openMenu() {
      this.$emit("toggle-blur");
    },
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    handleUserAgentDetails() {
      this.userAgentDetails = this.$root.getUserAgentDetails();
    },
  },

  created() {
    window.addEventListener("resize", this.handleUserAgentDetails);
    this.handleUserAgentDetails();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleUserAgentDetails);
  },

  mounted() {
    this.userAgentDetails = this.$root.getUserAgentDetails();
    this.urls = this.urls.reverse();
  },
};
</script>

<style lang="css" scoped>
.nav-link {
  font-family: Dana !important;
  color: #fff !important;
  font-weight: 200;
}
body {
  overflow: hidden !important;
}
.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: var(--bs-body-bg);
}
.liBig {
  width: unset;
  text-align: unset;
}
.liSmall {
  width: 100%;
  text-align: center;
}
.nav_collapse {
  border: 1px solid #efb10054;
  border-radius: 21px;
  padding: 7px;
  box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
  -webkit-box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
  -moz-box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
}

.shadow-hover-btn:hover {
  box-shadow: 0px 2px 5px 1px gray !important;
}
.nav-mobile {
  position: unset;
  width: unset;
  background-color: unset;
  z-index: unset;
  top: unset;
  right: unset;
}

@media all and (min-width: 992px) {
  .display-none-lg {
    display: none !important;
  }
}

@media all and (max-width: 1023px) {
  .padding-bottom-mobile-2 {
    padding-bottom: 3rem !important;
  }
  .d-cul-rev-mob {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .d-mobile-none {
    display: none !important;
  }
  .nav-mobile {
    position: absolute;
    width: 100%;
    background-color: black;
    z-index: 5555;
    top: 90px;
    right: -3px;
  }
}
</style>
