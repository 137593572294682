<template>
  <div class="dashboard d-flex">
    <Sidebar />
    <div class="main">
      <div class="center">
        <Navbar />
        <div><router-view :key="$route.fullPath" /></div>
        <Footer />
        <FooterMenu v-if="userAgentDetails.screenType === 'small'" />
      </div>
    </div>
  </div>
</template>
  
  <script>
import Navbar from "@/components/user/Navbar.vue";
import Sidebar from "@/components/user/Sidebar.vue";

import Footer from "@/components/user/Footer.vue";
import FooterMenu from "@/components/user/FooterMenu.vue";

export default {
  components: { Navbar, Sidebar, Footer, FooterMenu },
  data() {
    return {
      userAgentDetails: {},
    };
  },

  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    handleUserAgentDetails() {
      this.userAgentDetails = this.$root.getUserAgentDetails();
    },
  },

  created() {
    window.addEventListener("resize", this.handleUserAgentDetails);
    this.handleUserAgentDetails();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleUserAgentDetails);
  },

  mounted() {
    this.userAgentDetails = this.$root.getUserAgentDetails();
    this.checkMounted = true;
  },
};
</script>
  


<style scoped>
@import "../../public/assets/css/style.css";
@import "../../public/assets/css/light.css?v=0.31";
</style>