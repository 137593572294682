<template>
  <div>
    <nav class="navbar mb-0 navbar-expand-lg bg-dark navbar-light bg-light">
      <div class="container-fluid">
        <router-link :to="{ name: 'Public-Home' }" class="navbar-brand">
          <img
            src="/assets/logo/l2.svg"
            style="height: 3rem"
            class="img-fluid"
            alt=""
          />
        </router-link>
        <button
          @click="this.showMenu = !this.showMenu"
          class="navbar-toggler"
          style="border: none !important"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i
            v-if="this.showMenu"
            style="color: var(--ne-theme-font-color)"
            class="bi bi-x"
          ></i>
          <i
            v-if="!this.showMenu"
            style="color: var(--ne-theme-font-color)"
            class="bi bi-list"
          ></i>
        </button>
        <!-- <div
          class="collapse navbar-collapse"
          :class="{ show: showMenu }"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a
                class="nav-link text-center active"
                aria-current="page"
                href="#"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link text-center" href="#">Link</a>
            </li>

            <li class="nav-item">
              <a class="nav-link text-center" href="#">Link</a>
            </li>

            <li class="nav-item">
              <a class="nav-link text-center" href="#">Link</a>
            </li>

            <li class="nav-item">
              <a class="nav-link text-center" href="#">Link</a>
            </li>

            <li class="nav-item">
              <a class="nav-link text-center" href="#">Link</a>
            </li>
          </ul>
        </div> -->
      </div>
    </nav>
    <div
      :class="{ w100: showMenu }"
      id="mySidenav"
      style="background-image: url('/assets/images/Wave2.svg')"
      class="bg-dark sidenav"
    >
      <a
        href="javascript:void(0)"
        style="position: absolute; left: 20px; font-size: 2rem !important"
        class=""
        @click="this.showMenu = false"
        >&times;</a
      >

      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <img
                src="/assets/logo/l2.svg"
                style="height: 3rem"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="col-12 mt-5">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <router-link
                :to="{ name: 'PanelUser-Dashboard' }"
                class="btn btn-primary mx-1 w-50"
                style="
                  border: unset;
                  background-image: linear-gradient(
                    to right top,
                    #07976f,
                    #07936c,
                    #068e69,
                    #068a66,
                    #068663,
                    #068663,
                    #068663,
                    #068663,
                    #068a66,
                    #068e69,
                    #07936c,
                    #07976f
                  );
                  height: 35px;
                  font-size: 12px;
                  border-radius: 7px;
                "
              >
                &nbsp;&nbsp;&nbsp; ورود / ثبت‌نام &nbsp;&nbsp;&nbsp;&nbsp;
              </router-link>
            </div>
            <div class="col-12">
              <router-link href="#">رمز ارز</router-link>
            </div>
            <div class="col-12">
              <router-link href="#">مرور بازار</router-link>
            </div>
            <div class="col-12">
              <router-link href="#">طلا</router-link>
            </div>
            <div class="col-12">
              <router-link href="#">مبادلات مالی</router-link>
            </div>
            <div class="col-12">
              <router-link href="#">قبوض</router-link>
            </div>
            <div class="col-12">
              <router-link href="#">درگاه واسط</router-link>
            </div>
            <div class="col-12">
              <router-link href="#">وبلاگ</router-link>
            </div>
            <div class="col-12">
              <router-link href="#">درباره‌ما</router-link>
            </div>
            <div class="col-12">
              <router-link :to="{ name: 'Public-ContactUs' }">
                تماس با ما
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrTextLogo from "@/components/custom/PrTextLogo.vue";

export default {
  components: {
    PrTextLogo,
  },
  data() {
    return {
      isBoxOpen: false,
      showMenu: false,
      userAgentDetails: {
        screenType: null,
      },
      urls: [
        { text: "صفحه اصلی", name: "PublicHome", urlName: "", icon: "" },
        { text: "وبلاگ", name: "PublicBlogs", urlName: "", icon: "" },
        { text: "قوانین", name: "PublicTerms", urlName: "", icon: "" },

        { text: "درباره‌ما", name: "PublicAboutUs", urlName: "", icon: "" },
        { text: "تماس با ما", name: "PublicContactUs", urlName: "", icon: "" },
        { text: "همکاری", name: "", urlName: "", icon: "" },
        { text: "سوالات متداول", name: "PublicFaq", urlName: "", icon: "" },
        { text: "فروشگاه", name: "PublicStore", urlName: "", icon: "" },
      ],
    };
  },

  methods: {
    goLoginPage() {
      this.$router.push({ name: "PanelUser-Dashboard" });
    },
    openMenu() {
      this.$emit("toggle-blur");
    },
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    handleUserAgentDetails() {
      this.userAgentDetails = this.$root.getUserAgentDetails();
    },
  },

  created() {
    window.addEventListener("resize", this.handleUserAgentDetails);
    this.handleUserAgentDetails();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleUserAgentDetails);
  },

  mounted() {
    this.userAgentDetails = this.$root.getUserAgentDetails();
    this.urls = this.urls.reverse();
  },
};
</script>

<style lang="css" scoped>
.nav-link {
  font-family: Dana !important;
  color: #fff !important;
  font-weight: 200;
}
body {
  overflow: hidden !important;
}
.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: var(--bs-body-bg);
}
.liBig {
  width: unset;
  text-align: unset;
}
.liSmall {
  width: 100%;
  text-align: center;
}
.nav_collapse {
  border: 1px solid #efb10054;
  border-radius: 21px;
  padding: 7px;
  box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
  -webkit-box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
  -moz-box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
}

.shadow-hover-btn:hover {
  box-shadow: 0px 2px 5px 1px gray !important;
}
.nav-mobile {
  position: unset;
  width: unset;
  background-color: unset;
  z-index: unset;
  top: unset;
  right: unset;
}

@media all and (min-width: 992px) {
  .display-none-lg {
    display: none !important;
  }
}

@media all and (max-width: 1023px) {
  .padding-bottom-mobile-2 {
    padding-bottom: 3rem !important;
  }
  .d-cul-rev-mob {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .d-mobile-none {
    display: none !important;
  }
  .nav-mobile {
    position: absolute;
    width: 100%;
    background-color: black;
    z-index: 5555;
    top: 90px;
    right: -3px;
  }
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0 !important;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;

  overflow-x: hidden;
  transition: 0.5s;
  padding-top: unset;
  text-align: center;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.sidenav a {
  padding: 8px 8px 8px 8px;
  text-decoration: none;

  color: #fff;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #fff;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.w100 {
  width: 100% !important;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
</style>
