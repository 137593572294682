<template>
  <div>
    <div class="contianer-fluid bg-dark py-2" style="">
      <div class="row px-lg-5">
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <div class="row d-flex">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <img
                        src="/assets/logo/l2.svg"
                        style="height: 5rem"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="col-12">
                      <p class="text-muted">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                        چاپ و با استفاده از طراحان گرافیک است.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex">
                  <a class="mx-2" href="">
                    <img src="/assets/images/icon-email-night.svg" alt="" />
                  </a>

                  <a class="mx-2" href="">
                    <img src="/assets/images/icon-telegram-night.svg" alt="" />
                  </a>
                  <a class="mx-2" href="">
                    <img src="/assets/images/icon-twitter-night.svg" alt=""
                  /></a>
                  <a class="mx-2" href=""
                    ><img src="/assets/images/icon-facebook-night.svg" alt="" />
                  </a>
                  <a class="mx-2" href="">
                    <img src="/assets/images/icon-reddit-night.svg" alt="" />
                  </a>
                  <a class="mx-2" href="">
                    <img src="/assets/images/icon-instagram-night.svg" alt="" />
                  </a>
                  <a class="mx-2" href=""
                    ><img src="/assets/images/icon-discord-night.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="row d-flex justify-content-center">
                <div class="col-10 p-3">
                  <div class="row">
                    <div class="col-4">
                      <div class="row">
                        <div class="col-12">
                          <h5 class="text-white">درباره ما</h5>
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >درباره ما</a
                          >
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >تماس با ما</a
                          >
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >درباره ما</a
                          >
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >تماس با ما</a
                          >
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >درباره ما</a
                          >
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >تماس با ما</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="row">
                        <div class="col-12">
                          <h5 class="text-white">دسترسی سریع</h5>
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >درباره ما</a
                          >
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >تماس با ما</a
                          >
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >درباره ما</a
                          >
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >تماس با ما</a
                          >
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >درباره ما</a
                          >
                        </div>
                        <div class="col-12 mt-2">
                          <a
                            style="text-decoration: none"
                            class="text-muted"
                            href=""
                            >تماس با ما</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="row">
                        <div class="col-12">
                          <div
                            id="mapFooter"
                            style="
                              width: 100%;
                              height: 15rem;
                              border-radius: 21px;
                            "
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrTextLogo from "@/components/custom/PrTextLogo.vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
export default {
  data() {
    return {
      userAgentDetails: {
        screenType: null,
      },
      map: null,
      mapOptions: {
        center: [36.290153, 49.903799],
        zoom: 9,
      },
    };
  },

  components: { PrTextLogo },

  methods: {},
  mounted() {
    let vueThis = this;
    this.map = new L.map("mapFooter", this.mapOptions);
    this.mainLayer = new L.TileLayer(
      `https://tile.openstreetmap.org/{z}/{x}/{y}.png`
    );
    this.map.addLayer(this.mainLayer);
    var marker = L.marker(this.mapOptions.center).addTo(this.map);
  },
};
</script>

<style lang="css" scoped>
a {
  color: #fff;
}

#mapFooter {
  filter: sepia(1) hue-rotate(130deg) brightness(24%) saturate(1024%);
}
</style>
