<template>
  <div class="sidebar" id="sssssss" :class="{ active: sidebarActive }">
    <div
      class="sidebar-hd d-flex align-start just-between"
      style="justify-content: center"
    >
      <router-link to="/" class="logo d-block">
        <img class="logo-larg dark-logo" src="/assets/logo/l2.svg" alt />
        <img class="logo-larg light-logo" src="/assets/logo/l2.svg" alt />
        <img class="logo-sm" src="/assets/logo/l2.svg" alt />
      </router-link>
      <div class="closemenu" @click="toggleSidebar()">
        <i class="icon-arrow-right"></i>
      </div>
    </div>
    <div class="menu scrollhide">
      <div class="menu-title">منوی اصلی</div>
      <ul>
        <li>
          <router-link :to="{ name: 'PanelUser-Dashboard' }">
            <i class="icon-category4"></i>
            <span> داشبورد </span>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'PanelUser-Wallet' }">
            <i class="icon-strongbox-24"></i>
            <span> کیف‌های پول </span>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'PanelUser-Deposit-Rial' }">
            <i class="icon-strongbox-24"></i>
            <span> واریز وجه نقد </span>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'PanelUser-Market-Gold' }">
            <i class="icon-bank"></i>
            <span> بازار طلا </span>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'PanelUser-Exchange' }">
            <i class="icon-money-send"></i>
            <span>
              خرید و فروش رمزارز
              <div style="padding-left: 10px; color: #ffc045" dir="ltr">
                سریع
              </div>
            </span>
          </router-link>
        </li>

        <li>
          <a href="price"
            ><i class="icon-candle4"></i><span>قیمت ارز های دیجیتال</span></a
          >
        </li>
        <li>
          <a href="swap" target="_blank"
            ><i class="icon-arrow-swap"></i
            ><span
              >سواپ ارز دیجیتال
              <div style="padding-left: 10px; color: #ffc045" dir="ltr">
                جدید
              </div></span
            ></a
          >
        </li>
        <li>
          <a href="activities?type=exchange"
            ><i class="icon-calendar-search"></i
            ><span>تاریخچه فعالیت‌ها</span></a
          >
        </li>

        <li>
          <a href="global-transactions/payment"
            ><i class="icon-shopping-cart4"></i
            ><span>پرداخت در سایت‌های خارجی</span></a
          >
        </li>
        <li>
          <a href="service/gift-card"
            ><i class="icon-gift"></i><span>خرید گیفت کارت</span></a
          >
        </li>
        <li>
          <a href="usa/redeem_code"
            ><i class="icon-ticket-star4"></i><span>ردیم کد تتر </span></a
          >
        </li>
        <li>
          <a href="pro-exchange"
            ><i class="icon-svg"
              ><img src="https://kifpool.me/panel_v2/images/trad.svg" /></i
            ><span
              >بازار تتری
              <div style="padding-left: 10px; color: #ffc045">
                کارمزد 0.4%
              </div></span
            ></a
          >
        </li>
        <li class="sub">
          <a href="javascript:;"
            ><i class="icon-category-24"></i
            ><span>سایر خدمات <i class="icon-arrow-down-14"></i></span
          ></a>
          <ul class="submenu">
            <li>
              <a href="https://kifpool.me/live" target="_blank"
                ><i class="icon-buy-crypto4"></i
                ><span>قیمت طلا ٬ سکه و ارز</span></a
              >
            </li>
            <li>
              <a href="service/index"
                ><i class="icon-receipt"></i
                ><span>خرید شارژ ٬ پرداخت قبوض</span></a
              >
            </li>
            <li>
              <a href="coin-action?type=deposit&amp;coin=notcoin"
                ><img
                  src="https://kifpool.me/ui_v2/notcoin/images/notcoin.webp"
                  class="d-inline"
                  style="vertical-align: middle"
                  height="20"
                  width="20"
                />
                <span>واریز ناتکوین</span></a
              >
            </li>
            <li>
              <a href="service/flight"
                ><i class="icon-airplane"></i
                ><span> خرید بلیط هواپیما </span></a
              >
            </li>
            <li>
              <a href="credit-card"
                ><i class="icon-card-tick-14"></i
                ><span>مسترکارت اختصاصی</span></a
              >
            </li>
          </ul>
        </li>
      </ul>
      <div class="menu-title">دسترسی سریع</div>
      <ul>
        <li>
          <a
            href="javascript:;"
            onclick="if (!window.__cfRLUnblockHandlers) return false; $crisp.push(['do', 'chat:show']),$crisp.push(['do', 'chat:open'])"
            data-cf-modified-8e864970f09b6f86c1c29467-
            ><i class="icon-message-notif4"></i><span>پشتیبانی آنلاین</span></a
          >
        </li>
        <li class="sub">
          <a href="javascript:;"
            ><i class="icon-personalcard"></i
            ><span> مدیریت حساب<i class="icon-arrow-down-14"></i></span
          ></a>
          <ul class="submenu">
            <li>
              <a href="profile"
                ><i class="icon-setting-34"></i><span>تنظیمات حساب</span></a
              >
            </li>
            <li>
              <a href="verify"
                ><i class="icon-security-user"></i><span>احراز هویت</span></a
              >
            </li>
            <li>
              <a href="verify/cards"
                ><i class="icon-cards"></i><span>حساب های بانکی</span></a
              >
            </li>
            <li>
              <a href="tickets"
                ><i class="icon-support"></i><span>تیکت پشتیبانی</span></a
              >
            </li>
            <li>
              <a href="notifications"
                ><i class="icon-notification-bing4"></i
                ><span>اعلان های حساب</span></a
              >
            </li>
            <li>
              <a href="earn-money"
                ><i class="icon-money-add4"></i><span>کسب درآمد</span></a
              >
            </li>
            <li>
              <a href="faq"
                ><i class="icon-message-question"></i
                ><span>سوالات متداول</span></a
              >
            </li>
          </ul>
        </li>
        <li class="sub">
          <a href="javascript:;"
            ><i class="icon-import-14"></i
            ><span>دانلود اپلیکیشن <i class="icon-arrow-down-14"></i></span
          ></a>
          <ul class="submenu">
            <li>
              <a target="_blank" href="https://apps.apple.com/us/app/"
                ><i class="icon-apple"></i> <span>دانلود از اپ استور</span></a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=&gl=DE"
                ><div class="img">
                  <img src="https://kifpool.me/panel_v2/images/android.svg" />
                </div>
                <span>دانلود از گوگل پلی</span></a
              >
            </li>
            <li>
              <a target="_blank" href="https://cafebazaar.ir/app/"
                ><div class="img">
                  <img src="https://kifpool.me/panel_v2/images/android.svg" />
                </div>
                <span>دانلود از بازار</span></a
              >
            </li>
            <li>
              <a target="_blank" href="https://myket.ir/app/kifpool.me.v2"
                ><div class="img">
                  <img src="https://kifpool.me/panel_v2/images/android.svg" />
                </div>
                <span>دانلود از مایکت</span></a
              >
            </li>
            <li>
              <a target="_blank" href="https://kifpool.me/application"
                ><div class="img">
                  <img src="https://kifpool.me/panel_v2/images/android.svg" />
                </div>
                <span>دانلود مستقیم</span></a
              >
            </li>
          </ul>
        </li>
        <li>
          <a @click="this.$root.userLogout()" class="red">
            <i class="icon-logout"></i><span>خروج از حساب کاربری</span>
          </a>
        </li>
      </ul>
      <div class="menu-title">پشتیبانی تلفنی</div>
      <ul>
        <li>
          <a href="tel:+982166016592"
            ><i class="icon-call"></i>
            <span class="tel ne-font-fd">021-66016592</span></a
          >
        </li>
        <li>
          <a href="javascript:;"><span>پاسخگوی تلفنی ۹ صبح الی ۹ شب</span></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sidebarActive: false,
    };
  },

  methods: {
    toggleSidebar() {
      //   this.sidebarActive = !this.sidebarActive;

      let element = document
        .querySelector("#sssssss")
        .classList.contains("active");

      if (element) {
        document.querySelector('[id="sssssss"]').classList.remove("active");
      } else {
        document.querySelector('[id="sssssss"]').classList.add("active");
      }
    },
  },
};
</script>

<style lang="css" scoped>
div {
  font-family: var(--ne-theme-font-fd) !important;
}
li a.router-link-exact-active {
  background: var(--ne-theme-color-green-2) !important;
  color: #fff !important;
}

.nav-link dropdown-toggle.router-link-exact-active {
  color: red;
}
</style>