import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import LayoutPublic from "../layouts/Public.vue";
import LayoutUser from "../layouts/User.vue";

import {
  routes as userRoutes,
  middleware as userMiddleware,
} from "@/views/user/Routes";

import { routes as publicRoutes } from "@/views/public/Routes";
//import { routes as publicMobileRoutes } from "@/views/mobile/public/Routes";

const routes = [
  ...publicRoutes,
  ...userRoutes,
  // ...publicMobileRoutes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => {
      /**
       * خیلی مهم:
       * میدلور هر لایه حساب کاربری باید به طوری جداگانه فراخوانی شود
       * و در زیر قرار بگیرد
       *
       **/
      // console.log(record);

      userMiddleware(record, next, store.state.userIsAuthenticated);
    })
    /* eslint-disable */
  ) {
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
