<template>
  <NavBar v-if="userAgentDetails.screenType === 'large'" />
  <NavBarMobile v-if="userAgentDetails.screenType === 'small'" />
  <main
    role="main"
    :style="{
      marginTop: userAgentDetails.screenType === 'large' ? '84px' : 'unset',
    }"
  >
    <router-view :key="$route.fullPath" />
  </main>
  <Footer v-if="userAgentDetails.screenType === 'large'" />
  <FooterMobile v-if="userAgentDetails.screenType === 'small'" />
</template>

<script>
import NavBar from "@/components/public/NavBar.vue";
import NavBarMobile from "@/components/public/NavBarMobile.vue";
import Footer from "@/components/public/Footer.vue";
import FooterMobile from "@/components/public/FooterMobile.vue";
import { checkAuthUser } from "@/utils/checkAuth";

export default {
  components: { NavBar, Footer, NavBarMobile },
  data() {
    return {
      isBodyBlurred: false,
      userAgentDetails: {
        screenType: null,
      },
    };
  },
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    handleUserAgentDetails() {
      this.userAgentDetails = this.$root.getUserAgentDetails();
    },
    toggleBlur() {
      this.isBodyBlurred = !this.isBodyBlurred;
    },
    async checkUserLogin() {
      console.log("Checking");
      this.$store.commit("readUserToken");
      if (this.$store.state.userToken) {
        const authResponse = await checkAuthUser(
          "/v2/users/auth/validate",
          this.$store.state.userToken
        );
        console.log(authResponse.status);

        this.sleep(100).then(() => {
          if (authResponse.status) {
            this.checkLogin = true;
            this.$store.state.userDetails = authResponse.data;
            this.$store.commit("setUserToken", this.$store.state.userToken);
          } else {
            this.$store.commit("clearUserToken");
          }
        });
      } else {
        this.$store.commit("clearUserToken");
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleUserAgentDetails);
    this.handleUserAgentDetails();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleUserAgentDetails);
  },
  mounted() {
    this.userAgentDetails = this.$root.getUserAgentDetails();

    if (this.userAgentDetails.screenType == "small") {
      //this.$router.push({ name: "PublicMobile-Home" });
    }

    console.log(this.userAgentDetails);

    this.checkUserLogin();
  },
};
</script>

<style>
body {
  background: #0b0e12 !important;
}
</style>
