import store from "@/store";
import layout from "@/layouts/Public.vue";

// import { routes as authRoutes } from "./auth/Routes";
//import { routes as eventsRoutes } from "./events/Routes";
//import { routes as routesQuantumRandomNumbers } from "./quantumRandomNumbers/Routes";

/* نام گروه کابری برای مدیریت داشبرد یا پنل مورد نظر */
const groupName = store.state.publicRoutesGroupName;
/* مسیری ریشه‌ای که برای گروه کاربری در نظر گرفته‌ایم */
const pathRoot = store.state.publicRoutesPathRoot;
/* نام اصلی برای زیر مسیر‌های مدیریت داشبرد یا پنل مورد نظر */
const parentName = store.state.publicRoutesParentName;

// ------- Routes -------- -------- -------- -------- -------- -------- -------- //

export const routes = [
  {
    path: "/verify",
    name: "Verify",
    component: () => import("./Verify"),
    //redirect: (to) => {
    //return '/user/';
    //},
  },
  {
    path: pathRoot,
    name: parentName,
    component: layout,
    children: [
      {
        path: "",
        name: groupName + "-" + "Home",
        component: () => import("./Home"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: "/contact-us",
        name: groupName + "-" + "ContactUs",
        component: () => import("./Contactus"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: "/faq",
        name: groupName + "-" + "Faq",
        component: () => import("./Faq"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
      {
        path: "/terms",
        name: groupName + "-" + "Terms",
        component: () => import("./Terms"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: "/about-us",
        name: groupName + "-" + "AboutUs",
        component: () => import("./Aboutus"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: "/blogs",
        name: groupName + "-" + "Blogs",
        component: () => import("./Blog"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: "/store",
        name: groupName + "-" + "Store",
        component: () => import("./Store"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
      {
        path: "/store/:id",
        name: groupName + "-" + "StoreSingel",
        component: () => import("./SingelStore"),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
    ],
  },
];
