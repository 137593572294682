<template>
  <div class="navbar d-flex align-center">
    <div class="menu-toggle" @click="sidebarToggle()">
      <i class="icon-category4"></i> <span>منو</span>
    </div>
    <div class="userinfo sub">
      <div class="d-flex align-center">
        <div class="img">
          <img src="https://kifpool.me/panel-assets/img/ava-header.png" alt />
        </div>
        <div class="info">
          <div
            class="name"
            v-if="$store.state.firstName && $store.state.lastName"
          >
            {{ $store.state.firstName }} {{ $store.state.lastName }}
          </div>
          <div class="name">نام و نام خانوادگی ثبت نشده!</div>
          <div
            class="userid ne-font-fd"
            style="letter-spacing: 0px"
            id="mobId"
            dir="ltr"
            data-mobile="09011414296"
          >
            {{ $store.state.userDetails.mobile.slice(0, 5) }} ****
            {{ $store.state.userDetails.mobile.substring(9, 11) }}
          </div>
        </div>
      </div>
      <div class="submenu">
        <ul>
          <li>
            <a href="https://kifpool.me/member_v2/profile"
              ><i class="icon-personalcard"></i> تنظیمات حساب</a
            >
          </li>
          <li>
            <a href="https://kifpool.me/member_v2/verify"
              ><i class="icon-security-user"></i> احراز هویت</a
            >
          </li>
          <li>
            <a href="https://kifpool.me/member_v2/verify/cards"
              ><i class="icon-cards"></i> افزودن حساب بانکی</a
            >
          </li>
          <li>
            <a href="https://kifpool.me/member_v2/earn-money"
              ><i class="icon-money-add4"></i> کسب درآمد</a
            >
          </li>
          <li>
            <a
              href="https://kifpool.me/member_v2/logout"
              class="red"
              onclick="if (!window.__cfRLUnblockHandlers) return false; handleLogoutClick()"
              data-cf-modified-8e864970f09b6f86c1c29467-
              ><i class="icon-logout"></i> خروج از حساب</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="navbar-actions">
      <div class="item sub-toggle showall">
        <i class="icon-notification-bing"><div class="notifi-new"></div></i
        ><span>اطلاعیه ها</span>
        <div class="sub-box tab-container">
          <div class="checked-list scrollhide d-flex">
            <div class="checked-item tab-item active" data-tab-box="allNotif">
              اعلانات شما
            </div>
            <div class="checked-item tab-item" data-tab-box="arzNotif">
              اعلانات سیستمی
            </div>
          </div>
          <div class="tab-content" id="allNotif">
            <div class="notifi-list scrollhide">
              <div class="notifi-item noshow" id="notifId17817734">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">0 ثانیه پیش</div>
                    <h3>ورود موفق به حساب</h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  ورود موفق به حساب کاربری در تاریخ ۰۴ مهر ۱۴۰۳ ساعت ۱۹:۴۵ با
                  دستگاه
                </div>
              </div>
              <div class="notifi-item noshow" id="notifId17817715">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">36 ثانیه پیش</div>
                    <h3>ارسال کد ورود</h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  ورود موفق به حساب کاربری در تاریخ ۰۴ مهر ۱۴۰۳ ساعت ۱۹:۴۴ با
                  دستگاه web
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content" id="arzNotif">
            <div class="notifi-list scrollhide">
              <div class="notifi-item noshow" id="system_notifId257">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">4 ماه پیش</div>
                    <h3>آدرس اختصاصی شبکه تون اضافه شد.</h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  توجه: از این به بعد برای واریز تون کوین و نات کوین حتما از
                  آدرس و ممو (comment) اختصاصی خود استفاده کنید.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId255">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      بروزرسانی و توکن مایگریشن ارز SLG و CRE بطور خودکار داخل
                      صرافی کیف پول من انجام خواهد شد.
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  بروزرسانی و توکن مایگریشن ارز SLG و CRE بطور خودکار داخل صرافی
                  کیف پول من انجام خواهد شد. طی این بروزرسانی دارایی CRE کاربران
                  با نسبت 1:1 و دارایی SLG کاربران با نسبت 1:11.4 به ارز GAME2
                  تبدیل خواهد شد. امکان ترید ارز SLG و CRE تا تاریخ 10 May ساعت
                  6:30 به وقت تهران فعال خواهد بود. امکان پشتیبانی از ترید ارز
                  GAME2 پس از پایان بروزرسانی اطلاع رسانی خواهد شد.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId254">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>فروش ارز LGCY برای هولدر های این ارز انجام شد!</h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  فروش ارز LGCY برای هولدر های این ارز انجام شد! تبدیلتبدیل
                  موجودی به تتر برای کاربرانی که ارز LGCY در کیف پول خود نگهداری
                  کردند با قیمت 0.000129$ انجام شده است
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId253">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      بروزرسانی ارز AAG توسط شبکه مبدا کیف پول من پشتیبانی
                      نخواهد شد .
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  بروزرسانی ارز AAG توسط شبکه مبدا کیف پول من پشتیبانی نخواهد شد
                  . طبق اطلاع رسانی شبکه مبدا , امکان تبدیل ارز AAG برای کاربران
                  فراهم نخواهد بود و کاربرانی که ارز AAG در کیف پول خود نگهداری
                  کردند , بمدت یکماه امکان انتقال ارز به کیف پول های شخصی خود
                  خواهند داشت.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId252">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      تغییر نام ارز TAI به TAIOLD انجام شده و این ارز توسط شبکه
                      مبدا پشتیبانی نخواهد شد .
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  تغییر نام ارز TAI به TAIOLD انجام شده و این ارز توسط شبکه مبدا
                  پشتیبانی نخواهد شد . امکان برداشت ارز TIAOLD برای هولدر های
                  این ارز بمدت ۱ ماه پشتیبانی خواهد شد و کاربران امکان انتقال
                  دارایی به کیف پول شخصی خواهند داشت.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId251">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      بروزرسانی ارز QRDO به OPEN در صرافی کیف پول من تکمیل شده
                      است.
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  بروزرسانی ارز QRDO به OPEN در صرافی کیف پول من تکمیل شده است.
                  امکان ترید و انتقال این ارز برای کاربران فعال است.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId250">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      بروزرسانی و توکن سوآپ ارز STRAX بطور کامل در صرافی کیف پول
                      من انجام شده است.
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  بروزرسانی و توکن سوآپ ارز STRAX بطور کامل در صرافی کیف پول من
                  انجام شده است. طی این بروزرسانی دارایی کاربران با نسبت 1 به 10
                  تبدیل شده است. امکان ترید و انتقال این ارز برای کاربران در
                  صرافی کیف پول من فعال شده است.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId249">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      ⚙ بروزرسانی ارز LPOOL بطور خودکار در صرافی کیف پول من
                      انجام خواهد شد .
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  بروزرسانی ارز LPOOL بطور خودکار در صرافی کیف پول من انجام
                  خواهد شد . طی این بروزرسانی تغییری در مقدار دارایی کاربران
                  ایجاد نخواهد شد و امکان پشتیبانی از ورژن جدید ارز LPOOL پس از
                  پایان بروزرسانی اطلاع رسانی خواهد شد. طی پروسه بروزرسانی از
                  تاریخ April 29 مورخ 10 اردیبهشت ، انتقال و ترید ارز مسدود
                  خواهد شد .
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId248">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      ارز LGCY از لیست ارز های قابل خرید و فروش صرافی کیف پول من
                      حذف شده است.
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  ارز LGCY از لیست ارز های قابل خرید و فروش صرافی کیف پول من حذف
                  شده است. امکان برداشت ارز LGCY برای کاربران کیف پول من تا 24
                  ساعت آینده امکان پذیر خواهد بود. پس از پایان 24 ساعت ؛ این ارز
                  بطور خودکار برای کاربران فروخته خواهد شد و معادل دلاری این ارز
                  برای هولدر های ارز LGCY شارژ خواهد شد.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId247">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      ارز GF از لیست ارز های قابل خرید و فروش صرافی کیف پول من
                      حذف شده است.
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  ارز GF از لیست ارز های قابل خرید و فروش صرافی کیف پول من حذف
                  شده است. جهت جلوگیری از ، از دست رفتن دارایی کاربران ، این ارز
                  روی قیمت 0.39569$ برای کاربران فروخته شده و معادل دلاری برای
                  هولدر های این ارز شارژ شده است.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId246">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      ارز DELFI از لیست ارز های قابل خرید و فروش صرافی کیف پول
                      من حذف شده است.
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  ارز DELFI از لیست ارز های قابل خرید و فروش صرافی کیف پول من
                  حذف شده است. طبق اعلام شبکه مبدا ، بدلیل عدم پوشش دهی
                  استاندارد های لازم برای این ارز ، امکان پشتیبانی از این ارز
                  وجود نخواهد داشت.. امکان برداشت این ارز برای حد اکثر یکماه ،
                  در دسترس کاربران خواهد بود.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId245">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      بروزرسانی و تغییر نام ارز DOG بطور خودکار در صرافی کیف پول
                      من انجام خواهد شد.
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  بروزرسانی و تغییر نام ارز DOG بطور خودکار در صرافی کیف پول من
                  انجام خواهد شد. طی این بروزرسانی ، نماد ارز از DOG به DOGESWAP
                  تغییر خواهد کرد. این بروزرسانی تغییری در بلاکچین ارز ایجاد
                  نخواهد کرد . امکان انتقال این ارز طی پروسه بروزرسانی مسدود شده
                  و بلافاصله پس از تکمیل بروزرسانی ، فعال خواهد شد.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId244">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      بروزرسانی و تغییر نماد ارز OPEN بطور خودکار در صرافی کیف
                      پول من انجام شده است.
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  بروزرسانی و تغییر نماد ارز OPEN بطور خودکار در صرافی کیف پول
                  من انجام شده است. طی این بروزرسانی نماد ارز از OPEN به
                  OPENPLATFORM تغییر کرده است و ارز با نماد جدید در دسترس
                  کاربران قرار گرفته است.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId243">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">5 ماه پیش</div>
                    <h3>
                      ⚙ بروزرسانی و تغییر نام ارز QRDO بطور خودکار در صرافی کیف
                      پول من انجام خواهد شد.
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  بروزرسانی و تغییر نام ارز QRDO بطور خودکار در صرافی کیف پول من
                  انجام خواهد شد. طی این بروزرسانی ، نماد ارز از QRDO به OPEN
                  تغییر خواهد داشت . امکان انتقال ارز پس از پایان بروزرسانی با
                  نماد OPEN امکان پذیر خواهد بود. پشتیبانی از ترید این ارز ، پس
                  از پایان بروزرسانی اطلاع رسانی خواهد شد.
                </div>
              </div>
              <div class="notifi-item noshow" id="system_notifId240">
                <div class="notifi-item-hd d-flex align-center">
                  <div class="icon">
                    <i class="icon-notification-bing"></i>
                  </div>
                  <div class="info">
                    <div class="date">6 ماه پیش</div>
                    <h3>
                      تبدیل ارز ICE به WAGMI با نسبت 1 به 69 انجام شده است.
                    </h3>
                  </div>
                </div>
                <div class="notifi-text drop-slide">
                  تبدیل ارز ICE به WAGMI با نسبت 1 به 69 انجام شده است. امکان
                  ترید ارز WAGMI از سمت شبکه مبدا پشتیبانی نخواهد شد. امکان
                  برداشت ارز WAGMI بر بستر شبکه ERC20 امکان پذیر خواهد بود.
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-center just-between pd-t-10">
            <a
              href="https://kifpool.me/member_v2/notifications"
              class="btn btn-blue"
              >همه اعلان ها</a
            >
          </div>
        </div>
      </div>
      <div class="item sub-toggle">
        <i class="icon-message-24"></i><span>پشتیبانی</span>
        <div class="sub-box">
          <div
            onclick="if (!window.__cfRLUnblockHandlers) return false; $crisp.push(['do', 'chat:show']),$crisp.push(['do', 'chat:open'])"
            style="cursor: pointer"
            class="support-item d-flex align-center"
            data-cf-modified-8e864970f09b6f86c1c29467-
          >
            <div class="icon"><i class="icon-message-notif4"></i></div>
            <div class="info">پشتیبانی آنلاین</div>
            <div class="chat-online"></div>
          </div>
          <a href="tel:02191306781" class="support-item d-flex align-center">
            <div class="icon"><i class="icon-call"></i></div>
            <div class="info" dir="ltr">021-91306781</div>
          </a>
          <a
            href="https://kifpool.me/member_v2/tickets/create"
            class="support-item d-flex align-center"
          >
            <div class="icon">
              <i class="icon-message-question4"></i>
            </div>
            <div class="info">ارسال تیکت پشتیبانی</div>
          </a>
        </div>
      </div>
      <div class="item dark-toggle"><i class="icon-sun-1"></i></div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import PrTextLogo from "@/components/custom/PrTextLogo.vue";

export default {
  components: {
    PrTextLogo,
    userDetails: {},
  },
  methods: {
    handleConfirmationLogout() {
      this.$router.push({ name: "PanelUser-Logout" });
    },

    sidebarToggle() {
      let element = document
        .querySelector("#sssssss")
        .classList.contains("active");

      if (element) {
        document.querySelector('[id="sssssss"]').classList.remove("active");
      } else {
        document.querySelector('[id="sssssss"]').classList.add("active");
      }
      //document.querySelector('[id="sssssss"]').classList.add("active");
      //  document.querySelector('[id="sssssss"]').classList.remove("active");
    },
    logout() {
      Swal.fire({
        title: "آیا مطمین هستید ؟",
        text: "آیا میخواین از حساب کاربری خود خارج شوید ؟",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "بله",
        cancelButtonText: "لغو",
      }).then((result) => {
        if (result.isConfirmed) {
          this.handleConfirmationLogout();
        }
      });
    },
  },
};
</script>

<style scoped>
.router-link-active.sidebar-item-active {
  color: white !important;
  font-weight: bold;
}
</style>
