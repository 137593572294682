<template>
  <div class="copyright text-center">
    <p>یک راهکار جامع مالی برای اقتصاد مدرن</p>
    <div>NouraEx MVP (0.001)</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>