<template>
  <router-view :key="$route.fullPath" />
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    userLogout() {
      this.$router.push({ name: "PanelUser-Logout" });
    },
    getUserAgentDetails() {
      let screenHeight = window.innerHeight;
      let screenWidth = window.innerWidth;

      let userAgentDetails = {
        screenHeight: screenHeight,
        screenWidth: screenWidth,
        screenType: "",
        device: "",
        os: "",
        browser: "",
      };

      let min_number = 992;

      if (window.innerWidth < min_number) {
        userAgentDetails["screenType"] = "small";
      } else {
        userAgentDetails["screenType"] = "large";
      }

      let userAgent = navigator.userAgent;
      userAgentDetails["os"] = navigator.platform;

      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        userAgentDetails["device"] = "tablet";
        userAgentDetails["screenType"] = "small";
        if (screenWidth > screenHeight && screenWidth > min_number) {
          userAgentDetails["screenType"] = "large";
        }
      } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          userAgent
        )
      ) {
        userAgentDetails["device"] = "mobile";
        userAgentDetails["screenType"] = "small";
      }

      if (userAgent.split(",")[0] == "AndroidWebApk") {
        userAgentDetails["os"] = "Android";
        userAgentDetails["browser"] = userAgent.split(",")[0];
        userAgentDetails["manufacturer"] = userAgent.split(",")[1];
        userAgentDetails["model"] = userAgent.split(",")[2];
        userAgentDetails["version"] = userAgent.split(",")[3];
      } else if (userAgent.split(",")[0] == "IOSWebApp") {
        userAgentDetails["os"] = "IOS";
      }
      console.log(userAgentDetails);
      return userAgentDetails;
    },

    publicStyle(t = "set") {
      const url = "/assets/css/bootstrap.min.css";
      const a = document.querySelector(`link[href="${url}"]`);

      if (t === "set" && a === null) {
        const link = document.createElement("link");
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("href", url);
        document.head.appendChild(link);
      } else if (t === "unset" && a !== null) {
        document.querySelector(`link[href="${url}"]`).remove();
      }
    },
  },

  watch: {
    $route() {
      let n = null;
      this.sleep(100).then(() => {
        try {
          n = this.$route.name.split("-")[0];
          if (n === "Public" || n === "PublicMobile") {
            this.publicStyle("set");
          } else if (n === "PanelUser") {
            this.publicStyle("unset");
          }
        } catch (e) {
          n = false;
        }
      });
    },
  },

  mounted() {
    let n = null;
    this.sleep(100).then(() => {
      try {
        n = this.$route.name.split("-")[0];
        if (n === "Public") {
          this.publicStyle("set");
        } else if (n === "PanelUser") {
          this.publicStyle("unset");
        }
      } catch (e) {
        console.log(e);
        n = false;
      }
      console.log(n);
    });
  },
};
</script>

<style>
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--ne-theme-font-color) !important;
  line-height: 1.6;
  font-family: var(--ne-theme-font) !important;
}
label {
  font-family: var(--ne-theme-font) !important;
}

button {
  font-family: var(--ne-theme-font) !important;
}

input {
  font-family: var(--ne-theme-font) !important;
}

.form-control {
  font-family: var(--ne-theme-font) !important;
}

.input-text {
  font-family: var(--ne-theme-font) !important;
}

span {
  font-family: var(--ne-theme-font) !important;
}

p {
  font-family: var(--ne-theme-font) !important;
}

a {
  font-family: var(--ne-theme-font) !important;
}

.btn,
a.btn {
  font-family: var(--ne-theme-font) !important;
}

input {
  font-family: var(--ne-theme-font) !important;
}

html {
  overflow-x: hidden !important;
}
/* body {
  overflow-x: hidden !important;
} */
.Vue-Toastification__toast-body {
  font-family: var(--ne-theme-font);
  direction: rtl;
  text-align: right;
  margin-left: 7px;
  margin-right: 7px;
}
.Vue-Toastification__toast--warning {
  color: var(--bs-gray-900) !important;
}

input {
  border-color: var(--bs-gold-light-transparent) !important;
}

.card-1 {
  background-color: #1e1f25 !important;
  border-radius: 0.75rem !important;
}

.alert {
  padding: unset !important;
}
</style>
